/* eslint-disable camelcase */

import React from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import { useSubmitAwardsLotMutation } from '../../../components/api'
import Spinner from '../../../components/spinner'
import { NOOP } from '../../../components/utils'
import InfoIconSvg from '../../../images/info-sign.svg'

/**
 *
 * !!! Page !!!
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const Page = (props) => {
  // props
  const { openModal = true, setOpenModal = NOOP } = props
  const { listingId = '325' } = props

  // query
  const submitAwardsMutation = useSubmitAwardsLotMutation()

  // functions
  const onHide = () => {
    setOpenModal(false)
    submitAwardsMutation.reset()
  }
  const internalOnSave = () => {
    announceAwards()
  }

  /**
   * Upload the product manifest file.
   */
  const announceAwards = () => {
    console.debug('Sending announcement to backend...')
    submitAwardsMutation.mutate({ listingId })
  }

  return <>

        <Modal show={openModal} onHide={onHide}>
            <Modal.Body>
                <h5 className="m-0 mb-3"><img src={InfoIconSvg} className="mr-3" />Announce awards?</h5>

                <p>Participants will receive an email notification of awards.</p>

                {/* buttons */}
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={onHide} variant="outline-primary">Cancel</Button>
                    <Button type="button" onClick={internalOnSave} variant="primary">
                        {submitAwardsMutation.isLoading ? <Spinner /> : <span>Announce</span>}
                    </Button>
                </div>

                { submitAwardsMutation.isSuccess && <Alert variant="success" className="mb-0 mt-3 text-center">Announce successful!</Alert> }
                { submitAwardsMutation.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Error announcing awards. ' + submitAwardsMutation.error.message}</Alert> }

            </Modal.Body>
        </Modal>
      </>
}

export default Page
